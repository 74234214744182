<template>
  <banner-header/>
  <section-content-contact/>
  <section-FAQs/>
  <div id="sectionContact" class="container lg:mb-20 h-1px w-full bg-bw-08 mt-10"></div>
  <section-contact :dataCategory="dataCategory"/>
  <section-map address="Tầng 6, Cao ốc văn phòng Phượng Long 2, số 16 Nguyễn Trường Tộ, Phường 13, Quận 4, Thành phố Hồ Chí Minh, Việt Nam"/>
</template>
<script setup lang="ts">
import BannerHeader from './components/BannerHeader.vue'
import SectionContentContact from './components/SectionContentContact.vue'
import SectionFAQs from './components/SectionFaqs.vue'
import SectionContact from './components/SectionContact.vue'
import SectionMap from './components/SectionMap.vue'
import {useProducts} from '../../composables/products/index'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {getProductsCategory} = useProducts()
const {getFaqSettings} = useAppGlobalData()

const {data: dataCategory}: any = getProductsCategory(useRoute().params.langCode)
const lang = useRoute().params.lang

useDirectusCollectionSeo('contact_index', {}, lang)
const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)

const lang_switch = generateLangSwitch('contact', useRoute().params.langCode)
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = lang_switch
})
</script>
